"use client";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import type { User, Auth } from "firebase/auth";
import { usePathname } from "next/navigation";
import { authenticate, UserSchema } from "../services/auth-service";
import { toast } from "sonner";
import {
  setUserTokenToCookie,
  removeUserTokenFromCookie,
} from "../services/util-service";
import env from "../env";

type AuthContextProps = {
  user: {
    firebaseUser: User;
    userData: UserSchema;
  } | null;
  isLoading: boolean;
};

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  isLoading: true,
});

interface AuthContextProviderProps {
  children: React.ReactNode;
  firebase: { auth: Auth };
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
  firebase,
}) => {
  const userRef = useRef<User>();
  const pathname = usePathname();
  const [user, setUser] = useState<AuthContextProps["user"] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = useCallback(
    async (user: User) => {
      try {
        if (!userRef.current) {
          const uid = await user.getIdToken();
          setUserTokenToCookie(uid);
          const userData = await authenticate(uid, env.NEXT_PUBLIC_ROLE);
          setUser({ firebaseUser: user, userData });
        }
        userRef.current = user;
        setIsLoading(false);
      } catch (e) {
        toast.error("Authentication failed.");
        await firebase.auth.signOut();
        setIsLoading(false);
      }
    },
    [firebase.auth],
  );

  useEffect(() => {
    return firebase.auth.onIdTokenChanged((firebaseUser) => {
      setIsLoading(true);
      if (!firebaseUser) {
        userRef.current = undefined;
        setUser(null);
        removeUserTokenFromCookie();
      } else {
        void checkAuth(firebaseUser);
      }
      setIsLoading(false);
    });
  }, [firebase.auth, pathname, checkAuth]);

  useEffect(() => {
    const handle = setInterval(
      () => {
        const user = firebase.auth.currentUser;
        if (user) void user.getIdToken(true);
      },
      10 * 60 * 1000,
    );

    return () => clearInterval(handle);
  }, [firebase.auth]);

  return (
    <AuthContext.Provider value={{ user, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
