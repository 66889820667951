import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

const env = createEnv({
  server: {
    // DB
    POSTGRES_URL: z.string().min(1),
    // Firebase
    FIREBASE_PROJECT_ID: z.string().min(1),
    FIREBASE_CLIENT_EMAIL: z.string().min(1),
    FIREBASE_PRIVATE_KEY: z.string().min(1),
    // AWS
    AWS_S3_REGION: z.string().min(1),
    AWS_S3_COLLECTION_PHOTOS_BUCKET: z.string().min(1),
    AWS_S3_USER_REFERENCE_PHOTOS_BUCKET: z.string().min(1),
    AWS_REKOGNITION_COLLECTION: z.string().min(1),
    // MAYA
    MAYA_PUBLIC_KEY: z.string().min(1),
    MAYA_SECRET_KEY: z.string().min(1),
    // BE
    NODE_ENV: z.string().min(1),
    // SST
    IMAGE_PROCESSING_API_URL: z.string().url(),
  },
  client: {
    NEXT_PUBLIC_FIREBASE_API_KEY: z.string().min(1),
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: z.string().min(1),
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: z.string().min(1),
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: z.string().min(1),
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: z.string().min(1),
    NEXT_PUBLIC_FIREBASE_APP_ID: z.string().min(1),
    NEXT_PUBLIC_ROLE: z.enum(["customer", "photographer", "organizer"]),
  },
  runtimeEnv: {
    // server ----------------------------------------
    // DB
    POSTGRES_URL: process.env.POSTGRES_URL,
    // Firebase
    FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
    FIREBASE_CLIENT_EMAIL: process.env.FIREBASE_CLIENT_EMAIL,
    FIREBASE_PRIVATE_KEY: process.env.FIREBASE_PRIVATE_KEY,
    // AWS
    AWS_S3_REGION: process.env.AWS_S3_REGION,
    AWS_S3_COLLECTION_PHOTOS_BUCKET:
      process.env.AWS_S3_COLLECTION_PHOTOS_BUCKET,
    AWS_S3_USER_REFERENCE_PHOTOS_BUCKET:
      process.env.AWS_S3_USER_REFERENCE_PHOTOS_BUCKET,
    AWS_REKOGNITION_COLLECTION: process.env.AWS_REKOGNITION_COLLECTION,
    // MAYA
    MAYA_PUBLIC_KEY: process.env.MAYA_PUBLIC_KEY,
    MAYA_SECRET_KEY: process.env.MAYA_SECRET_KEY,
    // BE
    NODE_ENV: process.env.NODE_ENV,
    // SST
    IMAGE_PROCESSING_API_URL: process.env.IMAGE_PROCESSING_API_URL,
    // client ---------------------------------------------------------------
    NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN:
      process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    NEXT_PUBLIC_FIREBASE_PROJECT_ID:
      process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET:
      process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID:
      process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    NEXT_PUBLIC_ROLE: process.env.NEXT_PUBLIC_ROLE,
  },
});

export default env;
